import { FunctionalComponent, h, ComponentChildren } from 'preact';
import style from './style.css';

interface ButtonProps {
  children?: ComponentChildren;
  style?: { [property: string]: number | string };
  className?: string;
  disabled?: boolean;
  isLarge?: boolean;
  isFullWidth?: boolean;
  isSecondary?: boolean;
  onClick?: () => void;
}

const Button: FunctionalComponent<ButtonProps> = (props: ButtonProps) => {
  return <button
    class={[
      style.button,
      props.isSecondary ? style.buttonSecondary : undefined,
      props.isLarge ? style.buttonLarge : undefined,
    ].join(' ')}
    style={{
      ...props.style,
      width: props.isFullWidth ? '100%' : (props.style || {})['width'],
    }}
    className={props.className}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </button>
}

export default Button;