
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import style from './style.css';

const Logo: FunctionalComponent<{ withLink?: boolean }> = ({ withLink }) => {
  const logo = useMemo(() => <button
    disabled={!withLink}
    onClick={withLink ? (): void => { window.location.href = '/' } : undefined}
    class={style.logo}
    style={{
      cursor: withLink ? 'pointer' : 'default',
    }}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.205 5.84458C1.08942 4.80431 1.79545 3.85086 2.82419 3.65797L5.00005 3.25V19C5.00005 20.6569 6.34319 22 8.00005 22H17C17 22.5652 16.5668 23.0361 16.0035 23.083L5.00005 24C3.90005 24 3.00005 23.1 3.00005 22L1.205 5.84458Z" fill="black" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00005 0H20C21.1 0 22 0.9 22 2V18C22 19.1 21.1 20 20 20H9.00005C7.90005 20 7.00005 19.1 7.00005 18V2C7.00005 0.9 7.90005 0 9.00005 0ZM10.6 8.00007C10.6 7.17162 11.2716 6.5 12.1001 6.5L17.1 6.5C17.9285 6.5 18.6 7.17158 18.6 8V11.4C18.6 12.2284 17.9285 12.9 17.1 12.9H15.75L13.9338 14.6784C13.8652 14.7455 13.7847 14.7994 13.6965 14.8372C13.5999 14.8786 13.4958 14.9 13.3907 14.9H13.35C13.11 14.9 12.95 14.74 12.95 14.5V12.9H12.1C11.2716 12.9 10.6 12.2284 10.6 11.4L10.6 8.00007Z" fill="black" />
    </svg>
    <span>crit.cards</span>
  </button>, [withLink]);
  return logo;
}

export default Logo;