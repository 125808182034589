import { FunctionalComponent, h, createRef, Ref, ComponentChildren } from 'preact';
import { Stack, Direction } from 'swing';
import { useEffect, useState } from 'preact/hooks';
import Color from 'color';
import style from './style.css';
import Button from '../button/button';
import ProgressBar from '../progress-bar/progress-bar';
import Icon from '../icon/icon';

interface CardStackItem {
    data: unknown;
    content: ComponentChildren;
}

interface CardStackProps {
    items: CardStackItem[];
    actions: CardStackActions;
    onComplete: () => void;
    onBack: () => void;
}

interface Card {
    on: (event: string, listener: (e: unknown) => void) => void;
    throwIn: (coordinateX: number, coordinateY: number) => void;
    throwOut: (coordinateX: number, coordinateY: number, direction: typeof Direction) => void;
    destroy: () => void;
}

interface Stack {
    createCard: (element: HTMLElement, prepend?: boolean) => Card;
    getCard: (element: HTMLElement) => Card;
    on: (event: string, listener: (e: unknown) => void) => void;
}

type Action = {
    icon: string;
    label: string;
    color: string;
    callback: (itemData: unknown) => void;
}

export type CardStackActions = {
    LEFT: Action;
    RIGHT: Action;
};

const THROW_DISTANCE = 200;

const cardFeedbackStyle = {
    display: 'block',
    position: 'absolute',
    top: 16,
    zIndex: 1,
    width: 'auto',
    height: 44,
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '12px 12px',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '3px',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, .96)',
    opacity: 0,
    transform: 'scale(0.2)',
    transition: '.15s all ease-in-out',
}

const CardStack: FunctionalComponent<CardStackProps> = ({ items, actions, onComplete, onBack }: CardStackProps) => {

    const listRef: Ref<HTMLUListElement> = createRef();
    const [completed, setCompleted] = useState(0);
    const [stack, setStack] = useState<Stack>();
    const [cardElements, setCardElements] = useState<HTMLElement[]>([]);
    const [didInitialize, setDidInitialise] = useState<boolean>(false);
    const [directionHistory, setDirectionHistory] = useState<string[]>([]);

    useEffect(() => {
        if (!didInitialize && items.length && listRef.current !== undefined && listRef.current !== null) {

            const newCardElements: HTMLElement[] = [].slice.call(listRef.current?.children);
            setCardElements(newCardElements);
            const newStack: Stack = Stack({
                allowedDirections: [Direction.LEFT, Direction.RIGHT],
                maxThrowOutDistance: THROW_DISTANCE,
                minthrowOutDistance: THROW_DISTANCE,
                maxRotation: 30,
                throwOutConfidence: (xOffset: number, yOffset: number) => {
                    const xConfidence = Math.min(Math.abs(xOffset) / (THROW_DISTANCE / 4), 1);
                    const yConfidence = Math.min(Math.abs(yOffset) / (THROW_DISTANCE / 4), 1);
                    return Math.max(xConfidence, yConfidence);
                },
                transform: (element: HTMLElement, coordinateX: number, coordinateY: number, rotation: number) => {
                    let randomisedRotation = rotation;
                    let randomisedY = coordinateY;
                    const itemIndex = parseInt(element.getAttribute('data-index') || '', 10);
                    if (!isNaN(itemIndex)) {
                        randomisedRotation = Math.round(rotation + (itemIndex % 2 === 0 ? -1 : 1) * (itemIndex % 3 * 1.5));
                        randomisedY = Math.round(randomisedY + (itemIndex % 2 === 0 ? -1 : 1) * (itemIndex % 3 * 1.5));
                    }
                    element.style.transform = `translate3d(0, 0, 0) translate(${coordinateX}px, ${randomisedY}px) rotate(${randomisedRotation}deg)`;
                    const direction = Math.abs(coordinateX) < (THROW_DISTANCE / 4) ? null : coordinateX >= (THROW_DISTANCE / 4) ? 'RIGHT' : 'LEFT';
                    const feedbackElement = element.querySelector('.card-feedback');
                    if (feedbackElement instanceof HTMLElement) {
                        if (direction === null) {
                            feedbackElement.style.boxShadow = `0 0 0 480px rgba(255,255,255,0), inset 0 0 0 80px rgba(255,255,255,0)`;
                            feedbackElement.style.opacity = '0';
                            feedbackElement.style.transform = 'scale(0)';
                        } else {
                            feedbackElement.textContent = actions[direction].label;
                            feedbackElement.style.color = actions[direction].color;
                            const colorWithAlpha = Color(actions[direction].color).alpha(0.08);
                            feedbackElement.style.boxShadow = `0 0 0 480px rgba(${colorWithAlpha.red()}, ${colorWithAlpha.green()}, ${colorWithAlpha.blue()}, ${colorWithAlpha.alpha()}), inset 0 0 0 80px rgba(${colorWithAlpha.red()}, ${colorWithAlpha.green()}, ${colorWithAlpha.blue()}, ${colorWithAlpha.alpha()})`;
                            feedbackElement.style.borderColor = actions[direction].color;
                            feedbackElement.style.right = direction === 'LEFT' ? '16px' : 'auto';
                            feedbackElement.style.left = direction === 'RIGHT' ? '16px' : 'auto';
                            feedbackElement.style.opacity = '1';
                            feedbackElement.style.transform = `scale(1) rotate(${direction === 'LEFT' ? '' : '-'}10deg)`;
                        }
                    }
                },
            });
            setStack(newStack);
            const cards: Card[] = [];
            newCardElements.forEach((cardElement, i) => {
                cardElement.style.zIndex = `${i}`;
                cardElement.style.opacity = '0';
                cardElement.style.pointerEvents = 'none';
                const feedbackElement = cardElement.querySelector('.card-feedback');
                if (feedbackElement instanceof HTMLElement) {
                    feedbackElement.style.display = 'none';
                    feedbackElement.style.visibility = 'hidden';
                }
                const card = newStack.createCard(cardElement);
                cards.push(card);
            });

            for (let i = 0; i < cards.length; i++) {
                cards[i]?.throwOut(0, 0, Direction.LEFT);
            }

            for (let i = 0; i < cards.length - 3; i++) {
                cards[i]?.throwIn(0, 0);
            }

            for (let i = cards.length - 3; i < cards.length; i++) {
                setTimeout(() => {
                    newCardElements[i].style.opacity = '1';
                    newCardElements[i].style.pointerEvents = 'all';
                    cards[i]?.throwIn((Math.random() - 0.4) * 320, 80 + ((Math.random() - 0.15) * 80));
                    setTimeout(() => {
                        const feedbackElement = newCardElements[i].querySelector('.card-feedback');
                        if (feedbackElement instanceof HTMLElement) {
                            feedbackElement.style.display = 'block';
                            feedbackElement.style.visibility = 'visible';
                        }
                    }, 150);
                }, 250 + ((i + 3 - cards.length) * 100));
            }

            setTimeout(() => {
                newCardElements.forEach(cardElement => {
                    cardElement.style.opacity = '1';
                    cardElement.style.pointerEvents = 'all';
                    const feedbackElement = cardElement.querySelector('.card-feedback');
                    if (feedbackElement instanceof HTMLElement) {
                        feedbackElement.style.display = 'block';
                        feedbackElement.style.visibility = 'visible';
                    }
                });
            }, 750);

            newStack.on('throwout', (e: unknown) => {
                const event = e as { target: HTMLElement; throwDirection: string; throwOutConfidence: number };
                const itemIndex = parseInt(event.target.getAttribute('data-index') || '', 10);
                const direction = event.throwDirection === Direction.LEFT ? 'LEFT' : 'RIGHT';
                if (!isNaN(itemIndex)) {
                    event.target.style.opacity = '0';
                    event.target.style.pointerEvents = 'none';
                    actions[direction].callback(items[itemIndex].data);
                    setDirectionHistory(directionHistory => [...directionHistory, direction]);
                    setCompleted(completed => {
                        if (completed + 1 >= items.length) {
                            onComplete();
                        }
                        return completed + 1;
                    });
                }
            });

            newStack.on('throwin', () => {
                // Card has snapped back to the stack.
            });

            setDidInitialise(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, listRef, didInitialize]);

    return <div class={style.cardStack}>
        <div class={style.expandingSection}>
            <ProgressBar
                percentage={Math.round(completed / items.length * 100)}
                disabled={completed >= items.length}
            />
            <div style={{ height: 28 }} />
            <div class={style.stackContainer}>
                <ul ref={listRef} class={style.stack}>
                    {(items || []).slice().reverse().map((item, i) =>
                        <li
                            key={items.length - 1 - i}
                            data-index={items.length - 1 - i}
                            class={style.card}
                            style={{ opacity: 0, pointerEvents: 'none' }}
                            tabIndex={items.length - 1 - completed === i ? 0 : -1}
                        >
                            {item.content}
                            <span class="card-feedback" style={{ ...cardFeedbackStyle }}>{/* managed in `transform` */}</span>
                        </li>
                    )}
                </ul>
            </div>
        </div>
        <div>
            {Object.keys(actions).map((actionId: string) => {
                const actionType: keyof CardStackActions = actionId as keyof CardStackActions;
                return <Button
                    key={actionId}
                    style={{
                        width: 'calc(50% - 6px)',
                        marginBottom: 12,
                        backgroundColor: actions[actionType].color,
                    }}
                    disabled={completed >= items.length}
                    onClick={(): void => {
                        const cardIndex = items.length - 1 - completed;
                        stack?.getCard(cardElements[cardIndex]).throwOut(0, 0, Direction[actionType]);
                    }}
                >
                    <Icon iconName={actions[actionType].icon} iconColor="#fff" aria-label="" iconSize={20} />
                    <span>{actions[actionType].label} &nbsp;</span>
                </Button>
            })}
            <div>
                <Button
                    isSecondary
                    isFullWidth
                    style={{
                        width: 'calc(50% - 6px)',
                        marginBottom: 12,
                    }}
                    onClick={(): void => {
                        if (completed < 1) {
                            onBack();
                        } else {
                            const previousCardIndex = items.length - completed;
                            const previousCardElement = cardElements[previousCardIndex];
                            const previousCard = stack?.getCard(previousCardElement);
                            previousCard?.throwIn(directionHistory[directionHistory.length - 1] === 'LEFT' ? -THROW_DISTANCE : THROW_DISTANCE, 28);
                            previousCardElement.style.opacity = '1';
                            previousCardElement.style.pointerEvents = 'all';
                            setCompleted(completed => completed - 1);
                            setDirectionHistory(directionHistory => directionHistory.slice(0, -1));
                        }
                    }}
                >
                    <Icon iconSet="mi" iconName="undo" aria-label="Undo" iconSize={20} />
                    <span>Undo &nbsp; &nbsp;</span>
                </Button>
            </div>
        </div>
    </div >;
};

export default CardStack;