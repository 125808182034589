import { FunctionalComponent, h } from 'preact';

import Home from './home/home';
import Footer from './footer/footer';
import Header from './header/header';
import { useEffect } from 'preact/hooks';

const App: FunctionalComponent = () => {
    useEffect(() => {
        window.addEventListener('load', () => {
            setTimeout(() => {
                window.scrollTo(0, 1);
            }, 0);
        });
    }, [])
    return (
        <div id="preact_root">
            <Header />
            <Home />
            <Footer />
        </div>
    );
};

export default App;
