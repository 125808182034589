import { FunctionalComponent, h } from 'preact';
import Icon from '../icon/icon';
import style from './style.css';

const Footer: FunctionalComponent = () => {
    const suggestionLink = `mailto:suggestion@crit.cards?subject=Card%20suggestion&body=Hi%20Graham!%20I'd%20like%20to%20suggest%20a%20new%20card%20to%20include%20in%20crit.cards%3A%20...`;
    return (
        <footer class={style.footer}>
            <section>
                <p>Crit.cards is a design community resource by <a href="https://grahammacphee.com" target="_blank" rel="noreferrer">Graham Macphee</a>, Product Designer at WhatsApp.</p>
                <p><a href={suggestionLink} target="_blank" rel="noreferrer">
                    <span style={{ position: 'relative', top: 3 }}><Icon iconName="envelope" iconSize={16} iconColor="var(--color-content-3)" aria-label="" /></span>
                    <span>&nbsp; Suggest a new card</span>
                </a></p>
            </section>
        </footer>
    );
};

export default Footer;
