
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import style from './style.css';

interface IconProps {
  iconName: string;
  iconSet?: string;
  iconColor?: string;
  iconSize?: number;
  onClick?: () => void;
  'aria-label': string;
}

const Icon: FunctionalComponent<IconProps> = (props: IconProps) => {
  const { iconSet, iconName, iconColor, iconSize } = props;
  const ariaLabel = props['aria-label'];
  const icon = useMemo(() => <div
    class={`icon ${style.icon}`}
    style={{
      color: iconColor || 'var(--color-content-1)',
      fontSize: `${iconSize || 24}px`,
      width: `${iconSize || 24}px`,
      height: `${iconSize || 24}px`,
    }}
  >
    <i
      className="iconify"
      data-icon={`${iconSet || 'mdi'}:${iconName}`}
      style={{
        color: iconColor || 'var(--color-content-1)',
        fontSize: `${iconSize || 24}px`,
        width: `${iconSize || 24}px`,
        height: `${iconSize || 24}px`,
      }}
      aria-label={ariaLabel}
      data-icon-inline={false}
      data-width={iconSize || 24}
      data-height={iconSize || 24}
    />
  </div>, [iconColor, iconSize, iconSet, iconName, ariaLabel]);
  return icon;
}

export default Icon;