export interface Topic {
  icon: string;
  title: string;
  description: string;
}

export const PROMPT_TOPICS: { [id: string]: Topic } = {
  STRATEGY: {
    icon: 'lightbulb-on-outline',
    title: 'Strategy',
    description: 'The user problems, business goals and principles',
  },
  DIRECTION: {
    icon: 'routes',
    title: 'Direction',
    description: 'Broad approaches explored, scope and constraints',
  },
  INTERACTIONS: {
    icon: 'gesture-tap-hold',
    title: 'Interactions',
    description: 'Ease of use, flows and organisation of functionality',
  },
  VISUALS: {
    icon: 'palette',
    title: 'Visuals',
    description: 'Visual clarity, consistency and tone',
  },
}

export interface Prompt {
  title: string;
  description: string;
  topics: string[];
}

export const PROMPTS: Prompt[] = [
  { title: "We’ve identified a real problem people face", description: "Talk to people about their needs and their pains. Understand how important this problem is relative to others they face. Check whether they’ve put in the effort to look for a solution so far.", topics: ["STRATEGY"] },
  { title: "People are likely to choose our solution to do this job", description: "Investigate how people solve this problem today. Be sure they’re open to a new product to do this job, they don’t already have one, and they don’t have some other non-product solution.", topics: ["STRATEGY"] },
  { title: "There is a clear benefit for our business if we solve this problem", description: "Quantify the benefit you’d expect to see through data/market analysis to make it easier to compare opportunities.", topics: ["STRATEGY"] },
  { title: "This is one of the most important problems to solve right now", description: "Evaluate the opportunity cost of pursuing this compared to others available to make it easier to compare them.", topics: ["STRATEGY"] },
  { title: "We understand the trade-offs and risks in trying to solve this problem", description: "Take time to think about what could go wrong. Consider risks such as financial, safety, legal, regulatory and brand.", topics: ["STRATEGY"] },
  { title: "We are well equipped to see this through", description: "Ensure you have buy-in and support from the right people in your business. Ensure you have the right team and the time needed to execute on the opportunity effectively, learn and course-correct if needed.", topics: ["STRATEGY"] },
  { title: "We know how this contributes to our long-term plans", description: "Having a clear vision and goals for the future is important to align people and make meaningful progress over time. Ensure your work plays a clear role in moving things forward.", topics: ["STRATEGY", "DIRECTION"] },
  { title: "The scope of the solution is the right one to achieve our goals", description: "Ensure you’re balancing effort/value by considering what scope is right for your stage of development, scale, time to learn and the time horizon of your goals.", topics: ["DIRECTION"] },
  { title: "The approach builds on existing features and concepts", description: "Reducing the number of concepts a user has to understand makes products easier to use. Consider what users already know that could ease them into this experience.", topics: ["DIRECTION"] },
  { title: "We have explored a broad range of different approaches", description: "Challenge assumptions you have about how the problem can be solved. E.g. a discoverability problem could be solved through in-product changes, marketing or partnerships.", topics: ["DIRECTION"] },
  { title: "People will quickly grasp how it works based on prior experiences", description: "Ensure designs are easy to understand by using concepts that match the real world, removing redundant concepts and using accurate, non-technical language.", topics: ["INTERACTIONS"] },
  { title: "The most important actions will be obvious to people during each step", description: "Ensure people can complete tasks with ease by prioritising the most important content, call to actions and steps in the visual/structural hierarchy. Give power users a clear way to find advanced options.", topics: ["INTERACTIONS", "VISUALS"] },
  { title: "People will understand where they are and move to other sections easily", description: "Help people to move between tasks by providing clear navigation and using visual cues like elevated surfaces, steppers and breadcrumbs to explain how screens relate to each other.", topics: ["INTERACTIONS"] },
  { title: "People will feel in control because they can avoid or undo mistakes", description: "Help people avoid small mistakes big and small (e.g. ensure tap targets are large enough and ask for confirmation before complex or destructive actions). Give people ways to backtrack and undo.", topics: ["INTERACTIONS"] },
  { title: "People won’t have to think hard, invent or remember things by themselves", description: "Bias towards recognition over recall and use sensible defaults. Do the work to offer helpful suggestions for people and reduce the cognitive load they face when trying to complete a task.", topics: ["INTERACTIONS"] },
  { title: "People will get continual feedback so they know what is happening", description: "Ensure people know the product is ready and responsive in big and small ways. Show press-states on buttons/cards, show progress indicators and show confirmation of save status.", topics: ["INTERACTIONS"] },
  { title: "People with differing needs in different contexts will be able to use the product", description: "Consider how a person using a screen-reader could use the product, or someone with low digital literacy, or someone distracted by another task with only one hand free.", topics: ["INTERACTIONS"] },
  { title: "People can get back on track when something goes wrong", description: "Ensure errors are easy to notice and understand by using simple language and visuals to clarify severity. Help people to recover from errors with shortcuts to solve their issue.", topics: ["INTERACTIONS"] },
  { title: "People find it familiar and intuitive to interact with", description: "Use familiar concepts and interactions from elsewhere in your product or native platforms. Be consistent so people can use the product across platforms. Provide brief explanations inline, with paths to see additional guidance.", topics: ["INTERACTIONS"] },
  { title: "People will find it easy to scan and find what they're looking for", description: "Ensure people can quickly find what they’re looking for by visually grouping related information.", topics: ["VISUALS"] },
  { title: "People will perceive it as reliable due to smooth transitions", description: "Ensure people trust your product by avoiding flickering in the interface that wouldn’t exist with a physical product. Show a “skeleton” of the page as content loads so people can orient themselves quickly.", topics: ["VISUALS"] },
  { title: "People interpret the appropriate tone and personality from the content", description: "Use content and visuals purposefully to communicate your desired tone. E.g. professional, exciting, reassuring. Switch your tone based on the moment in the person’s journey.", topics: ["VISUALS"] },
  { title: "Every detail of the visuals and content has a purpose", description: "Reduce visual noise to help people focus on the key content and call to actions. Only include purposeful decoration that communicates an important tone or feeling for the product.", topics: ["VISUALS"] },
  { title: "A combination of visuals are used to convey information", description: "Ensure people can quickly grasp the meaning of a screen, whether they prefer to focus on words or colours or illustrations first.", topics: ["VISUALS"] }
];