import { FunctionalComponent, h, Fragment, Ref, createRef, RefObject } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import Icon from '../icon/icon';
import style from './style.css';

interface Section {
  id: string;
  titleIfSingle: string;
  titleIfMultiple: string;
}

interface SectionedListProps {
  sections: Section[];
  items: { section: string; content: h.JSX.Element }[];
}

const SectionedList: FunctionalComponent<SectionedListProps> = ({ sections, items }: SectionedListProps) => {
  const initialOpenSections: boolean[] = sections.map((section, i) => i === 0);
  const [openSections, setOpenSections] = useState(initialOpenSections);
  const sectionHeadingsRef: RefObject<HTMLButtonElement[]> = useRef([]);
  useEffect(() => {
    if (sectionHeadingsRef.current) {
      sectionHeadingsRef.current = sectionHeadingsRef.current.slice(0, sections.length);
    }
  }, [sections])
  return <Fragment>
    {sections.map((section, sectionIndex) => {
      const itemsInSection = items.filter(item => item.section === section.id);
      if (!itemsInSection.length) {
        return null;
      }
      return <Fragment key={section.id}>
        <button
          key={section.id}
          ref={(el): void => {
            if (el && sectionHeadingsRef.current) {
              sectionHeadingsRef.current[sectionIndex] = el;
            }
          }}
          class={style.sectionHeading}
          onClick={(): void => {
            setOpenSections((openSections: boolean[]): boolean[] => {
              const newOpenSections = openSections.slice();
              newOpenSections[sectionIndex] = !newOpenSections[sectionIndex];
              return newOpenSections;
            });
            if (!openSections[sectionIndex]) {
              setTimeout(() => {
                if (sectionHeadingsRef.current) {
                  window.scrollTo({
                    top: sectionHeadingsRef.current[sectionIndex].getBoundingClientRect().top -
                      document.body.getBoundingClientRect().top - 96,
                    behavior: "smooth",
                  });
                }
              }, 300);
            }
          }}
        >
          <h3>{itemsInSection.length === 1 ? section.titleIfSingle.replace('{n}', `${itemsInSection.length}`) : section.titleIfMultiple.replace('{n}', `${itemsInSection.length}`)}</h3>
          {openSections[sectionIndex] ?
            <Icon key={'up'} iconName="chevron-up" iconColor="var(--color-content-2)" aria-label="" /> :
            <Icon key={'down'} iconName="chevron-down" iconColor="var(--color-content-2)" aria-label="" />
          }
        </button>
        {openSections[sectionIndex] ? <div class={style.sectionContent}>
          {itemsInSection.map(item => item.content)}
        </div> :
          null
        }
      </Fragment>;
    })}
  </Fragment>
}

export default SectionedList;