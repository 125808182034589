import { FunctionalComponent, h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import Icon from '../icon/icon';
import style from './style.css';

interface Item {
  icon?: string;
  headline: string;
  body?: string;
  data: unknown;
}

interface CardSelectProps {
  items: Item[];
  onChange: (activeItems: unknown[]) => void;
}

const CardSelect: FunctionalComponent<CardSelectProps> = (props: CardSelectProps) => {
  const initialActiveItems: boolean[] = props.items.map(() => false);
  const [activeItems, setActiveItems] = useState(initialActiveItems);
  return <div class={style.selectCardContainer}>
    {props.items.map((item: Item, itemIndex: number) => {
      return <button
        key={item.data}
        class={style.selectCard}
        style={{
          border: activeItems[itemIndex] ? 'solid 3px var(--color-content-1)' : 'solid 3px transparent',
        }}
        onClick={(): void => {
          setActiveItems((activeItems: boolean[]): boolean[] => {
            const newActiveItems = activeItems.slice();
            newActiveItems[itemIndex] = !newActiveItems[itemIndex];
            props.onChange(props.items.filter((item, i) => newActiveItems[i]).map(item => item.data));
            return newActiveItems;
          });
        }}
      >
        <div style={{ opacity: activeItems[itemIndex] ? 1 : 0.64 }}>
          <Icon
            key={item.icon}
            iconName={item.icon || ''}
            iconSize={32}
            iconColor="var(--color-content-1)"
            aria-label={`Icon for ${item.headline}`}
          />
        </div>
        <br />
        <h3 style={{ fontSize: 18 }}>{item.headline}</h3>
        {item.body ? <Fragment>
          <p style={{ marginTop: 6, color: 'var(--color-content-2)' }}>{item.body}</p>
        </Fragment> :
          null
        }
      </button>
    })}
  </div>
}

export default CardSelect;