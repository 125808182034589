import { FunctionalComponent, h } from 'preact';
import Logo from '../logo/logo';
import style from './style.css';

const Header: FunctionalComponent = () => {
    return (
        <header class={style.header}>
            <section>
                <Logo withLink />
                <p>The shortcut to effective product design critique</p>
            </section>
        </header>
    );
};

export default Header;
