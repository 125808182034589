import { FunctionalComponent, h, Fragment, ComponentChildren } from 'preact';
import style from './style.css';
import CardStack, { CardStackActions } from '../card-stack/card-stack';
import { PROMPTS, PROMPT_TOPICS, Prompt } from '../prompt/prompt';
import { useEffect, useMemo, useState } from 'preact/hooks';
import Button from '../button/button';
import CardSelect from '../card-select/card-select';
import SectionedList from '../sectioned-list/sectioned-list';

const Home: FunctionalComponent = () => {

    const [activeTopics, setActiveTopics] = useState<string[]>([]);
    const [promptItems, setPromptItems] = useState<{ content: ComponentChildren; data: { index: number } & Prompt }[]>([]);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [resultsVisible, setResultsVisible] = useState(false);
    const [promptResults, setPromptResults] = useState<({ index: number; result: string } & Prompt)[]>([]);

    const resultSections = [
        {
            id: 'UNSURE',
            titleIfSingle: '1 thing may need work',
            titleIfMultiple: '{n} things may need work',
        },
        {
            id: 'YES',
            titleIfSingle: '1 thing is all good. Woo!',
            titleIfMultiple: '{n} things are all good. Woo!',
        },
    ];

    const topicItems = useMemo(() => Object.keys(PROMPT_TOPICS).map(topicId => ({
        icon: PROMPT_TOPICS[topicId].icon,
        headline: PROMPT_TOPICS[topicId].title,
        body: PROMPT_TOPICS[topicId].description,
        data: topicId,
    })), []);

    useEffect(() => {
        setPromptItems(PROMPTS.map((prompt, i) => ({
            content: <Fragment>
                <h3>{prompt.title}</h3>
                <br />
                <p style={{ opacity: 0.64 }}>{prompt.description}</p>
            </Fragment>,
            data: { index: i, ...prompt }
        })));
    }, []);

    const getActivePromptItems = (): { content: ComponentChildren; data: { index: number } & Prompt }[] => promptItems.filter(promptItem => {
        for (const topicId of activeTopics) {
            if (promptItem.data.topics.indexOf(topicId) > -1) {
                return true;
            }
        }
        return false;
    });

    const setResult = (resultType: string, itemData: unknown): void => {
        const newPromptData = itemData as Prompt & { index: number };
        const newPromptResult = {
            ...newPromptData,
            result: resultType,
        } as Prompt & { index: number; result: string };
        setPromptResults(promptResults => {
            let matchingResultIndex;
            promptResults.forEach((result, index) => {
                if (result.index === newPromptResult.index) {
                    matchingResultIndex = index;
                }
            });
            if (matchingResultIndex) {
                const newPromptResults = promptResults.slice();
                newPromptResults[matchingResultIndex] = newPromptResult;
                return newPromptResults;
            }
            return [...promptResults, newPromptResult];
        });
    }

    const actions: CardStackActions = {
        LEFT: {
            icon: 'check',
            label: 'Yes!',
            color: '#00933f',
            callback: (itemData: unknown): void => setResult('YES', itemData),
        },
        RIGHT: {
            icon: 'close',
            label: 'Unsure',
            color: '#c9392a',
            callback: (itemData: unknown): void => setResult('UNSURE', itemData),
        },
    }

    return (
        <div class={style.home}>
            {!cardsVisible && !resultsVisible ? <Fragment>
                <h2>What kind of feedback are you looking for?</h2>
                <p style={{ color: 'var(--color-content-2)' }}>{activeTopics.length ? `${activeTopics.length} selected` : 'Select at least one'}</p>
                <div style={{ height: window.innerWidth > 640 ? 56 : 32 }} />
                <CardSelect items={topicItems} onChange={(activeTopics): void => { setActiveTopics(activeTopics as string[]) }} />
                <div style={{ height: 16 }} />
                <Button isLarge isFullWidth onClick={(): void => setCardsVisible(true)} disabled={!activeTopics.length}>View cards</Button>
            </Fragment> :
                null
            }
            {cardsVisible && !resultsVisible ? <Fragment>
                <h2>As you see the designs, what do you think?</h2>
                <p style={{ color: 'var(--color-content-2)' }}>Swipe or select a button</p>
                <div style={{ height: window.innerWidth > 640 ? 40 : 32 }} />
                <CardStack
                    key={activeTopics.join()}
                    actions={actions}
                    items={getActivePromptItems()}
                    onComplete={(): void => {
                        setCardsVisible(false);
                        setResultsVisible(true);
                    }
                    }
                    onBack={(): void => {
                        setActiveTopics([]);
                        setCardsVisible(false);
                    }} />
            </Fragment> :
                null
            }
            {!cardsVisible && resultsVisible ? <Fragment>
                <h2>Here are some things to consider</h2>
                <p style={{ color: 'var(--color-content-2)' }}><span style={{ fontWeight: 600 }}>Tip:</span> To give friendly & effective critique, ask open questions, e.g. “What were your thoughts about the hierarchy here?”</p>
                <div style={{ height: window.innerWidth > 640 ? 56 : 32 }} />
                <SectionedList
                    sections={resultSections}
                    items={promptResults.map(promptResult => {
                        return {
                            section: promptResult.result,
                            content: <div class={style.card}>
                                <h3>{promptResult.title}</h3>
                                <p style={{ color: 'var(--color-content-2)' }}>{promptResult.description}</p>
                            </div>
                        }
                    })}
                />
            </Fragment> :
                null
            }
        </div >
    );
};

export default Home;
