import { FunctionalComponent, h } from 'preact';
import style from './style.css';

interface ProgressBarProps {
  percentage: number;
  disabled?: boolean;
}

const ProgressBar: FunctionalComponent<ProgressBarProps> = ({ percentage, disabled }: ProgressBarProps) => {
  return <div class={style.track} style={{ opacity: disabled ? 0.4 : 1 }}>
    <div class={style.bar} style={{ width: `${percentage}%` }} />
  </div>
}

export default ProgressBar;